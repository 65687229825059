/* You can add global styles to this file, and also import other style files */

@import url('../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css');
@import url('../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.material.lime.light.compact.css');
@import url('../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css');
@import url('../node_modules/devexpress-dashboard/dist/css/dx-dashboard.material.lime.light.compact.css');
@import '@angular/cdk/overlay-prebuilt.css';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/mdn-like.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';
@import '~codemirror/addon/hint/show-hint.css';
@import '~codemirror/addon/dialog/dialog.css';
@import '~codemirror/addon/display/fullscreen.css';
@import '~codemirror/addon/scroll/simplescrollbars.css';

:root {
  --input-warning-color: #ffc107;
}

.clearfix {
  clear: both;
  overflow: auto;
}
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.my-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.my-2 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.my-3 {
  margin-top: 3em !important;
  margin-bottom: 3em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mb-3 {
  margin-bottom: 3em !important;
}

.text-center {
  text-align: center;
}

.tile-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(26, 54, 126, 0.125);
}

.tile-caption {
  font-size: 1.4em;
  vertical-align: middle;
}

.content-block-card {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  overflow: hidden;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  background-color: #fff;

  .screen-x-small & {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);

    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
    background-color: #fff;
  }

  .screen-small & {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);

    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
    background-color: #fff;
  }
}

// Action buttons
.operation-buttons {
  .operation-buttons-bg-screen {
    display: flex;
    gap: 5px;

    &.flex-end {
      justify-content: flex-end;
    }
  }

  .operation-buttons-sml-screen {
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    grid-template-columns: auto auto;
  }
}

.wrap-text {
  overflow: hidden;
  word-break: break-word;
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
}

// changes the opacity of the disabled buttons.
.dx-state-disabled .dx-widget,
.dx-state-disabled.dx-widget {
  opacity: 1 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.rich-text-content {
  img {
    max-width: 100%;
  }
}

// ANGULAR DIALOG
.cdk-overlay-container {
  position: fixed;
  // z-index: 9999;
}
