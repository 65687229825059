html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.dx-card-title {
  font-weight: 400;
  font-size: 24px;
  margin: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

$side-panel-min-width: 60px;

.dx-list.alternating {
  .dx-list-item:nth-child(odd) {
    background-color: #f7f7f7;
  }
}

.project-setting-group {
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 3em;
  border: 1px solid #e0e0e0;
  //border-top: none;
  //border-bottom: none;
  border-radius: 2px;


  .dx-form-group-content {
    padding: 20px;
    border: none;
  }
}


.data-grid-flag-wrapper {
  text-align: center;

  .data-grid-flag {
    &.flag-true {
      color: #52c41a;
    }

    &.flag-false {
      color: #f5222d;
    }
  }
}

// close button added to toast
.dx-toast-closable {
  &::after {
    font-family: "FontAwesome";
    content: "\f00d";
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
}

// dark text
.dx-button.dx-button-default,
.dx-button.dx-button-success,
.dx-overlay-content.dx-toast-success .dx-toast-message,
.dx-overlay-content.dx-toast-success.dx-toast-closable,
.dx-page.dx-selection,
.dx-page-size.dx-selection, {
  color: rgb(54, 54, 64) !important;
}

// dark icon
.dx-button.dx-button-default .dx-icon,
.dx-button.dx-button-success .dx-icon,
.dx-overlay-content .dx-fa-button-icon .dx-icon,
.dx-overlay-content .dx-fa-button-icon-close .dx-icon,
.dx-overlay-content.dx-toast-success .dx-toast-icon {
  color: rgb(54, 54, 64) !important;
}


// change tooltip background color to white with black text
.dx-tooltip-wrapper .dx-overlay-content {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  border-radius: 4px;
}
